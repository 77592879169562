"use client";

import Link from "next/link";
import { useCallback, useState } from "react";
import { useRouter } from "next/navigation";
import { usePathname, useSearchParams } from "next/navigation";

import { TagVariant } from "@/lib/types";
import { cn, getUniqueTags } from "@/lib/utils";
import Tag from "@/components/ui/tag";
import { formatDate } from "@/lib/api-helpers";
import Arrow from "@/components/icons/Arrow";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { PROVINCES } from "@/lib/constants";

interface CalendarProps {
  data: any;
}

const Calendar = ({ data }: CalendarProps) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [selectedProvince, setSelectedProvince] = useState("all");

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);

      return params.toString();
    },
    [searchParams]
  );

  const items = data.nscAgenda.data
    .map((item: any) => {
      return {
        ...item,
        attributes: {
          ...item.attributes,
          tags: item.attributes.tags.map((tag: any) => {
            return tag.name;
          }),
        },
      };
    })
    .sort((a: any, b: any) => {
      return new Date(a.attributes.date).getTime() - new Date(b.attributes.date).getTime();
    });

  let uniqueTags: TagVariant[] = [];
  uniqueTags = getUniqueTags(items);

  return (
    <section className="w-full">
      {!data.noHeader && (
        <div className="mb-12 flex items-center gap-6">
          <h2 className="header-3">{data.title}</h2>
          <div className="h-px w-full flex-1 bg-nsc-dark-blue opacity-40" />
          <div className="flex gap-2">
            {uniqueTags.map((tag) => (
              <Tag key={tag} variant={tag} rounded />
            ))}
          </div>
        </div>
      )}

      <div>
        <table className="w-full">
          <tbody>
            {items.map((item: any) => {
              const date = formatDate(item.attributes.date);
              const numberDate = date.split(" ")[0];
              const monthDate = date.split(" ")[1];
              return (
                <tr key={item.id} className="grid grid-cols-12 border-t border-[rgba(23,28,96,0.4)] last:border-b">
                  <td className="col-span-12 flex items-center gap-2.5 border-b border-[rgba(23,28,96,0.4)] py-6 text-left md:col-span-2 md:justify-center md:border-b-0 md:border-r md:p-6">
                    <span className="font-oswald text-[54px] text-nsc-dark-blue">{numberDate}</span>{" "}
                    <span className="translate-y-3.5 font-oswald text-[22px] uppercase">{monthDate}</span>
                  </td>
                  <td className="col-span-12 flex items-center border-b border-[rgba(23,28,96,0.4)] py-6 text-left md:col-span-3 md:border-b-0 md:border-r md:p-6">
                    <h3 className="font-roboto text-xl font-bold">{item.attributes.title}</h3>
                  </td>
                  <td className="col-span-12 flex items-center border-[rgba(23,28,96,0.4)] py-6 text-left md:col-span-4 md:border-r md:p-6">
                    <p className="font-calluna text-base font-light">{item.attributes.description}</p>
                  </td>
                  <td className="col-span-12 flex items-center py-6 text-left md:col-span-3 md:justify-end md:p-6">
                    <Link
                      className="flex w-full flex-wrap items-center justify-between gap-2 underline underline-offset-2 md:w-fit md:justify-start lg:gap-6"
                      href={`/agenda/${item.attributes.slug}`}
                    >
                      Bekijk deze gebeurtenis{" "}
                      <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-nsc-dark-blue md:hidden lg:flex">
                        <Arrow color="white" />
                      </div>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {!data?.noLink && (
          <Link
            className="ml-auto flex w-full flex-wrap items-center justify-between gap-2 py-6 underline underline-offset-2 md:w-fit md:justify-start md:p-6 lg:gap-6"
            href={`/agenda`}
          >
            Bekijk de gehele agenda
            <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-nsc-dark-blue md:hidden lg:flex">
              <Arrow color="white" />
            </div>
          </Link>
        )}
      </div>

      {/* {data.ShowSearch && (
        <div
          className={cn(
            "-ml-2.5 mt-9 flex w-screen flex-col items-start justify-between gap-6 bg-nsc-yellow p-4 py-9 sm:ml-0 sm:w-full md:flex-row md:items-center md:gap-4 md:p-7 md:py-7",
            data.variant
          )}
        >
          <h3 className="max-w-[430px] font-oswald text-[27px] uppercase leading-[32px]">
            Weten wanneer er een evenement bij u in de buurt is?{" "}
          </h3>
          <div className="flex w-full flex-wrap items-center space-x-0 md:w-fit lg:flex-nowrap">
            <label>
              <span className="sr-only">Kies provincie</span>
              <Select
                value={selectedProvince}
                onValueChange={(value: string) => {
                  setSelectedProvince(value);
                }}
              >
                <SelectTrigger
                  className="h-[48px] border border-nsc-dark-blue bg-white text-base font-medium lg:w-[300px]"
                  aria-label="Kies provincie"
                >
                  <SelectValue placeholder="Heel Nederland" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={"all"}>Heel Nederland</SelectItem>
                  {PROVINCES.map((province: any) => (
                    <SelectItem value={province} key={province}>
                      {province}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </label>

            <Button
              onClick={() => {
                router.push("/agenda" + "?" + createQueryString("province", selectedProvince), {
                  scroll: pathname.includes("agenda") ? false : true,
                });
              }}
              className="w-full md:min-w-[250px] lg:w-fit"
              size="lg"
              variant="secondary"
              type="submit"
            >
              Filteren
            </Button>
          </div>
        </div>
      )} */}
    </section>
  );
};

export default Calendar;
